import React, { useContext, useState, useEffect } from "react"
import { FirebaseContext } from "../components/Firebase"

let fileReader
if (typeof window !== "undefined") {
  new FileReader()
}

const AddBook = () => {
  const { firebase } = useContext(FirebaseContext)
  const [authors, setAuthors] = useState([])
  const [bookCover, setBookCover] = useState("")
  const [bookName, setBookName] = useState("")
  const [authorId, setAuthorId] = useState("")
  const [summary, setSummary] = useState("")
  const [success, setSuccess] = useState(false)

  let isMounted = true

  useEffect(() => {
    return () => {
      isMounted = false
    }
  }, [])

  useEffect(() => {
    fileReader.addEventListener("load", () => {
      setBookCover(fileReader.result)
    })
  }, [])

  useEffect(() => {
    //query all available authors
    if (firebase) {
      firebase.getAuthors().then(snapshot => {
        if (isMounted) {
          const availableAuthors = []
          snapshot.forEach(doc => {
            availableAuthors.push({
              id: doc.id,
              ...doc.data(),
            })
          })

          setAuthorId()

          setAuthors(availableAuthors)
        }
      })
    }
  }, [firebase])

  return (
    <div>
      <form
        onSubmit={e => {
          e.preventDefault()
          console.log(authorId)
          firebase
            .createBook({
              bookCover,
              bookName,
              authorId,
              summary,
            })
            .then(() => {
              if (isMounted) {
                setSuccess(true)
              }
            })
        }}
      >
        <input
          value={bookName}
          placeholder="book name"
          onChange={e => {
            e.persist()
            setSuccess(false)
            setBookName(e.target.value)
          }}
        />
        <select
          value={authorId}
          onChange={e => {
            e.persist()
            setSuccess(false)
            setAuthorId(e.target.value)
          }}
        >
          {authors.map(a => (
            <option key={a.id} value={a.id}>
              {a.name}
            </option>
          ))}
        </select>
        <input
          type="file"
          onChange={e => {
            e.persist()
            setSuccess(false)
            fileReader.readAsDataURL(e.target.files[0])
          }}
        />
        <input
          value={summary}
          placeholder="summary"
          onChange={e => {
            e.persist()
            setSuccess(false)
            setSummary(e.target.value)
          }}
        />
        {success && <span>New book added successfully</span>}
        <button type="submit">Add new book</button>
      </form>
    </div>
  )
}

export default AddBook
